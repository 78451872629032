import { AppProps } from 'next/app'
import Head from 'next/head'

import * as Sentry from '@sentry/react'
import { AuthProvider } from 'contexts/auth'

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import AppErrorBoundary from 'components/ErrorBoundaries/AppErrorBoundary'
import {
  enableCustomErrorBoundaryInDevelopmentMode,
  isProduction,
} from 'components/ErrorBoundaries/const'

import 'react-toastify/dist/ReactToastify.css'
import 'routes/Workspaces/Workspace/routes/connectors/connector-builder-legacy.css'
import 'styles/globals.css'
import 'styles/graphql.css'
import { TrackingContextProvider } from 'contexts/tracking-context'
import { UnthreadWidget } from '../components/UnthreadWidget'
import { ImpersonationVisualNotification } from '../routes/components/Impersonation'

if (process.env.) {
  Sentry.init({
    dsn: 'https://281b07be93d245e99e11d6a90cbe59d1@o956397.ingest.sentry.io/5905751',
  })
}

// Disable default react error overlay in development mode
const disableReactErrorOverlay =
  enableCustomErrorBoundaryInDevelopmentMode && !isProduction
const noOverlayWorkaroundScript = {
  __html: `
  window.addEventListener('error', event => {
    event.stopImmediatePropagation()
  })

  window.addEventListener('unhandledrejection', event => {
    event.stopImmediatePropagation()
  })
`,
}

const title =
  process.env.NODE_ENV === 'development'
    ? '[Dev] Integration Console'
    : 'Integration Console'

export default function IntegrationConsoleApp({
  Component,
  pageProps,
}: AppProps) {
  const HeadContent = () => (
    <Head>
      {disableReactErrorOverlay && (
        <script dangerouslySetInnerHTML={noOverlayWorkaroundScript} />
      )}
      <meta
        name='viewport'
        content='minimum-scale=1, initial-scale=1, width=device-width'
      />
      <title>{title}</title>
      {process.env. && (
        <script src='/scripts/unthread.js' defer />
      )}
      <meta name='zd-site-verification' content='vulro40cv5kehslnvriuwp' />
    </Head>
  )

  return (
    <AppErrorBoundary>
      <TrackingContextProvider>
        <HeadContent />

        <AuthProvider>
          {process.env. ? (
            <GTMProvider state={{ id: 'GTM-TTWK8G4' }}>
              <Component {...pageProps} />
            </GTMProvider>
          ) : (
            <Component {...pageProps} />
          )}

          <UnthreadWidget />
        </AuthProvider>
      </TrackingContextProvider>
      <ImpersonationVisualNotification />
    </AppErrorBoundary>
  )
}
