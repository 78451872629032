import createAuth0Client from '@auth0/auth0-spa-js'
import * as FullStory from '@fullstory/browser'
import useApi from 'hooks/useApi'
import { createContext, useContext, useEffect } from 'react'
import useSWR, { KeyedMutator } from 'swr'
import { pushGtmEvent } from 'hooks/gtm'
import { useRouter } from 'next/router'
import { IS_LOCAL } from '../routes/Workspaces/Workspace/routes/connectors/components/config'
const REDIRECT_AFTER_LOGIN_PATH_KEY = 'redirectAfterLoginPath'

type AuthContextType = {
  self: any
  mutateSelf: KeyedMutator<any>
  loading: boolean
  refresh: () => void
  login: (returnTo?: string) => Promise<void>
  loginProd: (returnTo?: string) => Promise<void>
  logout: () => Promise<void>
  loginLink: (returnTo?: string) => string
}

const AuthContext = createContext<AuthContextType>({
  self: null,
  mutateSelf: () => Promise.resolve(),
  loading: true,
  refresh: () => null,
  login: async () => undefined,
  loginProd: async () => undefined,
  logout: async () => undefined,
  loginLink: () => '',
})

let handlingLoginCallback = false

export const AuthProvider = ({ children }) => {
  const {
    apiClient,
    apiFetcher,
    setToken,
    unsetToken,
    getImpersonationToken,
    unsetImpersonationToken,
  } = useApi()

  const workspaceId = useRouter().query.workspaceId

  const { data: self, mutate } = useSWR(
    workspaceId ? `console-self?workspaceId=${workspaceId}` : 'console-self',
    apiFetcher,
  )

  const allowTracking =
    !!process.env. && !getImpersonationToken()
  const userLoggedIn = !!self?.user?.id && !!self?.user?.email

  useEffect(() => {
    if (
      allowTracking &&
      userLoggedIn &&
      !self.user?.email.includes('@integration.app') &&
      self.orgs.find((org) => org.id === self.workspace?.orgId)?.trialEndDate
    ) {
      FullStory.init({ orgId: 'o-1GYY72-na1' })
      window['_fs_ready'] = function () {
        FullStory.identify(self?.user?.id, {
          displayName: self?.user?.email,
          email: self?.user?.email,
          defaultAccountId: self?.user?.defaultAccountId,
          defaultWorkspaceId: self?.user?.defaultWorkspaceId,
        })
        const sessionUrl = FullStory.getCurrentSessionURL()
        if (localStorage.getItem('userSessionURL') != sessionUrl) {
          // FIXME: strictNullCheck temporary fix
          // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
          localStorage.setItem('userSessionURL', sessionUrl)
          if (sessionUrl) {
            void apiClient.post('/user-session', {
              sessionUrl: sessionUrl,
            })
            void apiClient.post('https://eokxfzducbk76x7.m.pipedream.net', {
              user: self?.user,
              sessionUrl: sessionUrl,
            })
          }
        }
      }
    }
  }, [allowTracking, userLoggedIn, self?.user?.id])

  useEffect(() => {
    if (allowTracking && userLoggedIn) {
      pushGtmEvent('user_identify', {
        userEmail: self.user.email,
        userName: self.user?.name,
      })
    }
  }, [allowTracking, userLoggedIn, self?.user?.email])

  async function createAuthClient() {
    const domain = process.env.login.integration.app
    const clientId = process.env.eSR6dKpwDEHLFMErq3eCndAS2vx8Ouri

    if (!domain || !clientId) {
      throw new Error(
        'Environment variables login.integration.app and eSR6dKpwDEHLFMErq3eCndAS2vx8Ouri must be defined',
      )
    }

    return await createAuth0Client({
      domain: domain,
      client_id: clientId,
    })
  }

  async function createAuthProdClient(redirectUri?: string) {
    const domain = process.env.login.integration.app
    const clientId = process.env.eSR6dKpwDEHLFMErq3eCndAS2vx8Ouri_PROD

    if (!domain || !clientId) {
      throw new Error(
        'Environment variables login.integration.app and eSR6dKpwDEHLFMErq3eCndAS2vx8Ouri_PROD must be defined',
      )
    }

    return await createAuth0Client({
      domain: domain,
      client_id: clientId,
      redirect_uri: redirectUri,
    })
  }

  async function login(returnTo?: string) {
    if (IS_LOCAL) {
      localStorage.setItem('loginApp', 'dev')
    }
    const client = await createAuthClient()
    localStorage.setItem(
      REDIRECT_AFTER_LOGIN_PATH_KEY,
      returnTo ?? window.location.pathname,
    )
    await client.loginWithRedirect({
      redirect_uri: window.location.origin,
    })
  }
  async function loginProd(returnTo?: string) {
    const prodClient = await createAuthProdClient(window.location.origin)
    localStorage.setItem(
      REDIRECT_AFTER_LOGIN_PATH_KEY,
      returnTo ?? window.location.pathname,
    )
    if (IS_LOCAL) {
      localStorage.setItem('loginApp', 'prod')
    }
    await prodClient.loginWithRedirect({
      // Optionally include any additional parameters required for the second app's login
    })
  }

  function refresh() {
    void mutate()
  }

  async function logout() {
    unsetToken()
    unsetImpersonationToken()
    const client = await createAuthClient()
    await client.logout({
      returnTo: window.location.origin,
    })
  }

  async function handleLoginCallback() {
    let isProdLogin: boolean = false
    if (IS_LOCAL) {
      isProdLogin = localStorage.getItem('loginApp') === 'prod'
    }
    const client = isProdLogin
      ? await createAuthProdClient()
      : await createAuthClient()
    // Check if the URL contains the code and state parameters
    const urlParams = new URLSearchParams(window.location.search)
    if (!urlParams.has('code') || !urlParams.has('state')) {
      throw new Error('Invalid callback URL')
    }

    // Process the login state

    await client.handleRedirectCallback()

    const claims = await client.getIdTokenClaims()
    if (claims) {
      if (isProdLogin) {
        localStorage.setItem('prodToken', claims.__raw)
      } else {
        unsetImpersonationToken()
        setToken(claims.__raw)
      }

      let redirectAfterLoginPath = localStorage.getItem(
        REDIRECT_AFTER_LOGIN_PATH_KEY,
      )
      // Don't allow absolute URLs to be used as redirect paths
      // it's a security risk
      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      if (redirectAfterLoginPath.includes('://')) {
        localStorage.removeItem(REDIRECT_AFTER_LOGIN_PATH_KEY)
        redirectAfterLoginPath = null
      }
      if (
        redirectAfterLoginPath &&
        !redirectAfterLoginPath.includes('/login')
      ) {
        localStorage.removeItem(REDIRECT_AFTER_LOGIN_PATH_KEY)
        window.location.href = redirectAfterLoginPath
      } else {
        window.location.href = window.location.origin
      }
    }
  }

  if (typeof window !== 'undefined') {
    const query = window.location.search
    if (query.includes('code=')) {
      if (!handlingLoginCallback) {
        handlingLoginCallback = true
        void handleLoginCallback()
      }
      return null
    }
  }

  function loginLink(returnTo?: string) {
    return (
      `/login?` + (returnTo ? `returnTo=${encodeURIComponent(returnTo)}` : '')
    )
  }

  return (
    <AuthContext.Provider
      value={{
        self,
        mutateSelf: mutate,
        loading: typeof self === 'undefined',
        login,
        logout,
        loginProd,
        refresh,
        loginLink,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}
